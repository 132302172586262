import { template as template_a6be08e81d7448faac4b4621b8b83f55 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a6be08e81d7448faac4b4621b8b83f55(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
