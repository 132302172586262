import { template as template_4caf8a6f37e347e8af0945368306e522 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_4caf8a6f37e347e8af0945368306e522(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
